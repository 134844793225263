import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import Layout from "../components/Layout";
import { ApiOutlined, SnippetsOutlined,UserOutlined } from "@ant-design/icons";
const FirstPage = () => {
  const PageOuter = styled.div`
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: center;
    width: 100vw;
    height: 100vh;
      
  `;
  const NoteOuter = styled.div`
    width: 60vw;
    padding: 15px;
      margin: 10px auto;
      background: wheat;
      border: 1px solid #cccccc;
      border-radius: 8px;
    line-height: 150%;
  `;
  const LinkButton = styled(Link)`
    display: flex;
    flex-direction: row;
    width: 60vw;
    padding: 15px;
    background-color: #007bff;
    border-radius: 6px;
    color: white;
    font-weight: bold;
    font-size: 1.2rem;
    border: 1px solid #096dd9;
    text-align: center;
    margin: 10px auto;
    & > .btn-icon {
      margin-top: 8px;
      margin-right: 8px;
    }
  `;
  return (
    <Layout>
      <PageOuter>
        <div>
          <LinkButton to="/enterinfo">
            <SnippetsOutlined className="btn-icon" />
            <span>纸质证书申领</span>
          </LinkButton> 
          <LinkButton to="/sendInfos">
            <UserOutlined size={20} className="btn-icon" />
            <span>我的证书申领</span>
          </LinkButton>
          {/*<LinkButton to="/OnlineStep">*/}
          {/*  <ApiOutlined size={20} className="btn-icon" />*/}
          {/*  <span>电子证书申领</span>*/}
          {/*</LinkButton>*/}
        </div>
      </PageOuter>
    </Layout>
  );
};

export default FirstPage;
